import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import Box from '@ui-v2/core/Box/Box';
import ButtonV2 from '@ui-v2/core/Button/Button';
import Modal from '@ui-v2/core/Modal/Modal';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import Button, { ButtonType } from '../Button/Button';
import StepNavigation from '../Steps/StepNavigation';

export interface Props {
  children: React.ReactNode;
  className?: string;
  confirmButtonText?: string;
  id: string;
  onCloseModal: () => void;
  onConfirm: () => void;
  title?: string;
}

const StyledArrowIcon = styled(ArrowIcon)`
  width: 16px;
  height: 16px;
  fill: #fff;
  transform: rotate(180deg);
`;

const StyledStepNavigation = styled(StepNavigation)(
  ({ theme }) => css`
    margin: -${theme.spacings[16]}px;
  `,
);

const SearchMobileModal = ({
  children,
  confirmButtonText,
  id,
  onCloseModal,
  onConfirm,
  title,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      footer={
        <Box display="flex" justifyContent="space-between">
          <ButtonV2 onClick={onCloseModal} variant="secondary">
            {t('Close')}
          </ButtonV2>
          <ButtonV2 onClick={onConfirm}>
            {confirmButtonText ?? t('Confirm')}
          </ButtonV2>
        </Box>
      }
      header={
        <Box flex={1}>
          <StyledStepNavigation
            leftContent={
              <Button buttonType={ButtonType.RAW} onClick={onCloseModal}>
                <StyledArrowIcon />
                <span className="aux">{t('Close')}</span>
              </Button>
            }
            title={title}
          />
        </Box>
      }
      id={id}
      isOpen
      mobileSize="full-screen"
      onOpenChange={onCloseModal}
      p={0}
    >
      {children}
    </Modal>
  );
};

export default SearchMobileModal;
