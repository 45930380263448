import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { UseComboboxGetItemPropsOptions } from 'downshift';
import { legacySpacings } from '@ui-v2/theme/layout';
import { ReactComponent as ListItemIcon } from '../../assets/icons/arrow.svg';
import { resetButton } from '../../styles/cssReset';
import { Item } from './AutocompleteTypes';

export interface Props {
  extraItem?: Item;
  getItemProps: (options: UseComboboxGetItemPropsOptions<Item>) => object;
  highlightedValue: string | null;
  id: string;
  index: number;
  inverted?: boolean;
  isExpandedView: boolean;
  item: Item;
  onSelectItem: (selectedItem: Item) => void;
  showDetailIcon?: boolean;
  showItem: boolean;
  showListIcon: boolean;
  showSubtree: boolean;
}

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ItemButton = styled.button<{
  inverted?: boolean;
  isHighlighted?: boolean;
  showDetailIcon?: boolean;
}>(
  ({
    inverted,
    isHighlighted,
    showDetailIcon,
    theme: { colours, spacings },
  }) => {
    return [
      resetButton,
      css`
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: ${spacings['4']}px ${spacings['16']}px;
        padding-left: ${inverted
          ? legacySpacings.ExtraLarge
          : spacings['16']}px;
        background: ${colours.surface.default};
        color: ${colours.text.default};
        cursor: pointer;

        ${StyledListItemIcon} {
          fill: ${colours.icons.default};
        }

        &:hover {
          background: ${colours.surface.hover};
        }
      `,
      isHighlighted &&
        css`
          background: ${colours.surface.hover};
          cursor: pointer;

          &:hover {
            background: ${colours.surface.hover};
          }
        `,
      showDetailIcon &&
        css`
          justify-content: flex-start;

          &::before {
            width: 8px;
            height: 8px;
            margin-right: ${spacings['8']}px;
            content: '';
          }
        `,
    ];
  },
);

const StyledSubtreeList = styled.ul`
  width: 100%;
  padding: 0;
`;

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ isSelected }) => [
  css`
    width: 15px;
    height: 28px;
    transform: rotate(${isSelected ? -90 : 90}deg);
  `,
]);

const AutocompleteItem = ({
  extraItem,
  getItemProps,
  highlightedValue,
  id,
  index,
  inverted,
  isExpandedView,
  item,
  onSelectItem,
  showDetailIcon,
  showItem,
  showListIcon,
  showSubtree,
}: Props) => {
  return (
    <ListItem>
      {showItem && (
        <ItemButton
          {...getItemProps({ item, index })}
          data-cy="autocomplete-item"
          inverted={inverted}
          isHighlighted={item.value === highlightedValue}
          onClick={() => onSelectItem(item)}
          showDetailIcon={showDetailIcon}
        >
          <span data-id={`${id}-value`}>{`${item.value}`}</span>
          {showListIcon && (
            <StyledListItemIcon aria-hidden="true" isSelected={showSubtree} />
          )}
        </ItemButton>
      )}
      {showSubtree && (
        <StyledSubtreeList>
          {item.subItems.map((subItem, subItemIndex) => (
            <AutocompleteItem
              extraItem={extraItem ? undefined : item}
              getItemProps={getItemProps}
              highlightedValue={highlightedValue}
              id={id}
              index={index + subItemIndex}
              inverted={!isExpandedView}
              isExpandedView={isExpandedView}
              item={subItem}
              key={subItem.value}
              onSelectItem={onSelectItem}
              showDetailIcon={extraItem !== undefined}
              showItem
              showListIcon={false}
              showSubtree={subItem.subItems.length > 0}
            />
          ))}
        </StyledSubtreeList>
      )}
    </ListItem>
  );
};

export default AutocompleteItem;
