import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { Dayjs } from 'dayjs';
import useTranslation from 'next-translate/useTranslation';
import { Item } from '@ui/components/Autocomplete/AutocompleteTypes';
import usePartnerRouter from '@web/context/hooks/usePartnerRouter';
import usePartnerPassengersConfig from '@web/context/hooks/usePassengersConfig';
import {
  SearchQueryParams,
  State,
  Action,
  ACTION_TYPE,
} from '@web/types/searchWidgetTypes';
import { transformUTMParam } from '@web/utils/offerUtils';
import {
  constructAddPaxQueryParams,
  constructNewPaxAges,
  constructPassengerTypes,
  constructRemovePaxQueryParams,
  constructStationQueryParameters,
  setSearchQueryParamToLocalStorage,
  getSearchWidgetDefaultState,
} from '@web/utils/search/searchWidgetUtils';
import { constructSearchResultQueryPushParams } from '@web/utils/searchUtils';
import useGetAvailability from '../hooks/useGetAvailability';
import useGetStations from '../hooks/useGetStations';
import { Props as SearchWidgetProps } from '../SearchWidget/SearchWidget';

const searchWidgetReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTION_TYPE.UPATE_PAX_AGES: {
      const paxTypeAges = {
        ...state.paxTypeAges,
        ...action.payload.paxTypeAges,
      };

      return {
        ...state,
        paxTypeAges,
      };
    }

    case ACTION_TYPE.SELECT_DATES: {
      return {
        ...state,
        [SearchQueryParams.DEPARTURE_DATE]: action.payload.dateRange.from,
        [SearchQueryParams.RETURN_DATE]: action.payload.dateRange.to,
      };
    }
    case ACTION_TYPE.SELECT_ORIGIN:
      setSearchQueryParamToLocalStorage(
        SearchQueryParams.ORIGINS,
        action.payload.origins.join(','),
      );

      return {
        ...state,
        [SearchQueryParams.ORIGINS]: action.payload.origins,
      };
    case ACTION_TYPE.SELECT_DESTINATION:
      setSearchQueryParamToLocalStorage(
        SearchQueryParams.DESTINATIONS,
        action.payload.destinations.join(','),
      );

      return {
        ...state,
        [SearchQueryParams.DESTINATIONS]: action.payload.destinations,
      };
    case ACTION_TYPE.TOGGLE_ONEWAY:
      return {
        ...state,
        [SearchQueryParams.IS_ONEWAY]: !state.isOneWay,
      };

    case ACTION_TYPE.RESET_STATE:
      return action.payload.state;

    default:
      return state;
  }
};

const context = createContext<SearchWidgetProps>({
  destinationAutocompleteItems: [],
  originAutocompleteItems: [],
  passengerTypes: [],
  departureDate: null,
  returnDate: null,
  addPassenger: () => {},
  removePassenger: () => {},
  updateAge: () => {},
  onSelectedDateRange: () => {},
  onSelectOriginItem: () => {},
  onSelectDestinationItem: () => {},
  onToggleOneWay: () => {},
  onSearchClick: () => {},
  onSwitchClick: () => {},
  onShowDatePickerPopup: () => {},
  onShowDatePickerModal: () => {},
  showDatePickerPopup: false,
  showDatePickerModal: false,
});

export default context;

export const SearchWidgetProvider = ({
  children,
  currency,
  departureDate,
  destinations,
  isOneWay,
  origins,
  paxTypeAges,
  residency,
  returnDate,
  utmCampaign,
  utmMedium,
  utmSource,
}: {
  children: ReactNode;
  currency?: Maybe<string>;
  departureDate?: Maybe<Dayjs>;
  destinations?: Maybe<string[]>;
  isOneWay?: Maybe<boolean>;
  origins?: Maybe<string[]>;
  paxTypeAges?: Maybe<{ [key: string]: number[] }>;
  residency?: Maybe<string>;
  returnDate?: Maybe<Dayjs>;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
}) => {
  const [isSearchFormValid, setIsSearchFormValid] = useState(true);
  const { passengerRules } = usePartnerPassengersConfig();
  const { push, query } = usePartnerRouter();

  const initialState = useMemo(
    () =>
      getSearchWidgetDefaultState({
        origins,
        destinations,
        departureDate,
        returnDate,
        isOneWay,
        paxTypeAges,
        passengerRules,
      }),
    [
      departureDate,
      destinations,
      isOneWay,
      origins,
      passengerRules,
      paxTypeAges,
      returnDate,
    ],
  );

  const [state, dispatch] = useReducer(searchWidgetReducer, initialState);

  const [showDatePickerPopup, setShowDatePickerPopup] = useState(false);

  const [showDatePickerModal, setShowDatePickerModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!state[SearchQueryParams.DEPARTURE_DATE] && departureDate) {
      dispatch({
        payload: {
          state: getSearchWidgetDefaultState({
            origins,
            destinations,
            departureDate,
            returnDate,
            isOneWay,
            paxTypeAges,
            passengerRules,
          }),
        },
        type: ACTION_TYPE.RESET_STATE,
      });
    }
  }, [
    departureDate,
    destinations,
    isOneWay,
    origins,
    passengerRules,
    paxTypeAges,
    returnDate,
    state,
  ]);

  const {
    destinationStations,
    isDestinationStationsLoading,
    isOriginStationsLoading,
    originStations,
    selectedDestinationItem,
    selectedOriginItem,
  } = useGetStations({
    origins: state.origins,
    destinations: state.destinations,
  });

  const {
    checkIfArrivalDateIsDisabled,
    checkIfDepartureDateIsDisabled,
    isForceOneWay,
  } = useGetAvailability({
    origins: state.origins,
    destinations: state.destinations,
  });

  const onSelectOriginItem = useCallback(
    (item: Item) =>
      dispatch({
        payload: {
          origins: constructStationQueryParameters(originStations, item),
        },
        type: ACTION_TYPE.SELECT_ORIGIN,
      }),
    [originStations],
  );

  const onSelectedDateRange = useCallback((dateRange: DateRange) => {
    dispatch({
      payload: { dateRange },
      type: ACTION_TYPE.SELECT_DATES,
    });
  }, []);

  const onSelectDestinationItem = useCallback(
    (item: Item) =>
      dispatch({
        payload: {
          destinations: constructStationQueryParameters(
            destinationStations,
            item,
          ),
        },
        type: ACTION_TYPE.SELECT_DESTINATION,
      }),
    [destinationStations],
  );

  const onToggleOneway = useCallback(
    () => dispatch({ type: ACTION_TYPE.TOGGLE_ONEWAY }),
    [],
  );

  const onAddPassenger = useCallback(
    (paxType: string) => {
      dispatch({
        type: ACTION_TYPE.UPATE_PAX_AGES,
        payload: {
          paxTypeAges: constructAddPaxQueryParams({
            paxType,
            passengerRules,
            currentPaxTypeAges: state.paxTypeAges[paxType],
          }),
        },
      });
    },
    [passengerRules, state.paxTypeAges],
  );

  const onRemovePassenger = useCallback(
    (paxType: string) =>
      dispatch({
        type: ACTION_TYPE.UPATE_PAX_AGES,
        payload: {
          paxTypeAges: constructRemovePaxQueryParams({
            paxType,
            currentPaxTypeAges: state.paxTypeAges[paxType],
          }),
        },
      }),
    [state.paxTypeAges],
  );

  const onUpdateAge = useCallback(
    (updateIndex: number, newAge: number, paxType: string) =>
      dispatch({
        type: ACTION_TYPE.UPATE_PAX_AGES,
        payload: {
          paxTypeAges: constructNewPaxAges({
            updateIndex,
            newAge,
            paxType,
            currentPaxTypeAges: state.paxTypeAges[paxType],
          }),
        },
      }),
    [state.paxTypeAges],
  );

  const disabledDays =
    !state.returnDate &&
    state.departureDate &&
    !state.isOneWay &&
    !isForceOneWay
      ? (arrivalDate: Dayjs) =>
          checkIfArrivalDateIsDisabled(arrivalDate, state.departureDate)
      : checkIfDepartureDateIsDisabled;

  const passengerTypes = useMemo(
    () =>
      constructPassengerTypes({
        passengerRules,
        passengerQueryParams: state.paxTypeAges,
      }),
    [passengerRules, state.paxTypeAges],
  );

  const searchUrl = useMemo(
    () =>
      constructSearchResultQueryPushParams({
        origins: state.origins,
        destinations: state.destinations,
        departureDate: state.departureDate as Dayjs,
        returnDate: state.returnDate as Dayjs | null,
        isOneWay: isForceOneWay || state.isOneWay,
        currency,
        residency,
        paxTypeAges: state.paxTypeAges,
        utmSource: transformUTMParam(query.utm_source) || utmSource,
        utmMedium: transformUTMParam(query.utm_medium) || utmMedium,
        utmCampaign: transformUTMParam(query.utm_campaign) || utmCampaign,
      }),
    [
      state.origins,
      state.destinations,
      state.departureDate,
      state.returnDate,
      state.isOneWay,
      state.paxTypeAges,
      currency,
      residency,
      utmSource,
      query.utm_source,
      utmMedium,
      query.utm_medium,
      utmCampaign,
      query.utm_campaign,
      isForceOneWay,
    ],
  );

  const onSearchClick = useCallback(() => {
    const isSearchFormValid =
      state.origins.length > 0 &&
      state.destinations.length > 0 &&
      state.departureDate;

    if (isSearchFormValid) {
      push({
        pathname: searchUrl.pathname,
        query: searchUrl.query,
        shallow: true,
      });
    } else {
      setIsSearchFormValid(false);
    }
  }, [
    state.origins.length,
    state.destinations.length,
    state.departureDate,
    push,
    searchUrl,
  ]);

  const onSwitchClick = useCallback(() => {
    if (!selectedOriginItem || !selectedDestinationItem) {
      return;
    }
    onSelectOriginItem(selectedDestinationItem);

    dispatch({
      payload: {
        origins: constructStationQueryParameters(
          originStations,
          selectedDestinationItem,
        ),
      },
      type: ACTION_TYPE.SELECT_ORIGIN,
    });

    dispatch({
      payload: {
        destinations: constructStationQueryParameters(
          originStations,
          selectedOriginItem,
        ),
      },
      type: ACTION_TYPE.SELECT_DESTINATION,
    });
  }, [
    selectedOriginItem,
    selectedDestinationItem,
    onSelectOriginItem,
    originStations,
  ]);

  const originErrorMessage =
    !isSearchFormValid && !(state.origins.length > 0)
      ? t('Please select an origin')
      : undefined;

  const destinationErrorMessage =
    !isSearchFormValid && !(state.destinations.length > 0)
      ? t('Please select a destination')
      : undefined;

  const datePickerErrorMessage =
    !isSearchFormValid && !state.departureDate ? t('Invalid dates') : undefined;

  return (
    <context.Provider
      value={{
        destinationAutocompleteItems: destinationStations,
        originAutocompleteItems: originStations,
        passengerTypes,
        addPassenger: onAddPassenger,
        updateAge: onUpdateAge,
        removePassenger: onRemovePassenger,
        onSelectOriginItem,
        onSelectDestinationItem,
        disabledDays,
        onSelectedDateRange,
        onSearchClick,
        onToggleOneWay: onToggleOneway,
        onSwitchClick,
        departureDate: state.departureDate,
        returnDate: isForceOneWay ? null : state.returnDate,
        isOneWay: isForceOneWay || state.isOneWay,
        selectedDestinationItem,
        selectedOriginItem,
        originErrorMessage,
        destinationErrorMessage,
        datePickerErrorMessage,
        enableRoundTrip: !isForceOneWay,
        isOriginAutocompleteLoading: isOriginStationsLoading,
        isDestinationAutocompleteLoading: isDestinationStationsLoading,
        onShowDatePickerPopup: setShowDatePickerPopup,
        onShowDatePickerModal: setShowDatePickerModal,
        showDatePickerPopup,
        showDatePickerModal,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useSearchWidget = () => useContext(context);
