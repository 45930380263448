import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import { ReactComponent as AirplaneRightIcon } from '@ui/assets/icons/airplane-right.svg';
import { ReactComponent as LocationIcon } from '@ui/assets/icons/location.svg';
import { createCSSFilterFromHex } from '@ui/styles/base';

const StyledPlaneIcon = styled(AirplaneRightIcon)(({ theme: { colours } }) => [
  css`
    width: 18px;
    min-width: 18px;
    height: 18px;
    fill: ${colours.icons.default};
  `,
]);

const StyledTrainIcon = styled.span<{
  backgroundSrc: string | null;
}>(({ backgroundSrc, theme: { colours } }) => [
  createCSSFilterFromHex(colours.icons.default),
  css`
    width: 18px;
    height: 18px;
    padding-left: 20px;
    background-image: url(${backgroundSrc});
    background-repeat: no-repeat;
  `,
]);

const StyledTrainPlaneIcon = styled.span<{
  backgroundSrc: string | null;
}>(({ backgroundSrc, theme: { colours } }) => [
  createCSSFilterFromHex(colours.icons.default),
  css`
    width: 18px;
    height: 18px;
    padding-left: 20px;
    background-image: url(${backgroundSrc});
    background-repeat: no-repeat;
  `,
]);

const StyledLocationIcon = styled(LocationIcon)(({ theme: { colours } }) => [
  css`
    width: 18px;
    min-width: 18px;
    height: 18px;
    fill: ${colours.icons.default};
  `,
]);

const SearchWidgetTransportIcon = ({
  airportAndTrainIcon,
  trainIcon,
  transportType,
}: {
  airportAndTrainIcon?: Maybe<ImageWithConfigFragment>;
  trainIcon?: Maybe<ImageWithConfigFragment>;
  transportType?: string;
}) => {
  switch (transportType) {
    case 'AIRPORT':
      return <StyledPlaneIcon />;
    case 'TRAIN':
      return <StyledTrainIcon backgroundSrc={trainIcon?.asset.url ?? null} />;
    case 'AIRPORT_AND_TRAIN':
      return (
        <StyledTrainPlaneIcon
          backgroundSrc={airportAndTrainIcon?.asset.url ?? null}
        />
      );
    default:
      return <StyledLocationIcon />;
  }
};

export default SearchWidgetTransportIcon;
