import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GridRow } from '@ui/components/Grid/Grid';
import { mqMin } from '@ui/styles/base';

export const StyledGridRow = styled(GridRow)(
  ({ theme }) => css`
    height: 100%;
    padding: ${theme.spacings['16']}px;
    gap: ${theme.spacings['16']}px;

    ${mqMin.Small} {
      padding: 0 ${theme.spacings['24']}px;
      gap: 0;
    }
  `,
);
