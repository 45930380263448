import { AriaAttributes } from 'react';
import { Dayjs } from 'dayjs';
import useTranslation from 'next-translate/useTranslation';
import { Modifiers, DayPickerBase } from 'react-day-picker';
import { DisplayType, Language } from '@shared/types/enums';
import Popup, { Align } from '../Popup/Popup';
import MediaQuery from '../Responsive/MediaQuery';
import DatePicker from './DatePicker';
import DatePickerInput from './DatePickerInput';
import DatePickerModal from './DatePickerModal';
import { detectCloseActionRequired } from './utils/datePickerUtils';

export interface Props {
  align?: Align;
  ariaLabel?: AriaAttributes['aria-label'];
  className?: string;
  dateRange: DateRange;
  disabledDays?: (day: Dayjs) => boolean;
  enableRoundTrip?: boolean;
  errorMessage?: string;
  isOneWay: boolean;
  locale: Language;
  modifiers?: Partial<Modifiers>;
  onShowDatePickerModal: (s: boolean) => void;
  onShowDatePickerPopup: (s: boolean) => void;
  setDateRange: (dateRange: DateRange) => void;
  showDatePickerModal: boolean;
  showDatePickerPopup: boolean;
  toggleIsOneWay: () => void;
  weekStartsOn?: DayPickerBase['weekStartsOn'];
}

const DatePickerContainer = ({
  align = 'left',
  ariaLabel,
  className,
  dateRange,
  disabledDays,
  enableRoundTrip,
  errorMessage,
  isOneWay,
  locale,
  modifiers,
  onShowDatePickerModal,
  onShowDatePickerPopup,
  setDateRange,
  showDatePickerModal,
  showDatePickerPopup,
  toggleIsOneWay,
  weekStartsOn,
}: Props) => {
  const { t } = useTranslation();

  const onSetDateRange = (updatedDateRange: DateRange) => {
    const checkIfCloseActionIsNeeded = detectCloseActionRequired({
      newFrom: updatedDateRange.from,
      newTo: updatedDateRange.to,
      to: dateRange.to,
      isOneWay,
    });

    if (checkIfCloseActionIsNeeded) {
      onShowDatePickerPopup(false);
      onShowDatePickerModal(false);
    }
    setDateRange(updatedDateRange);
  };

  return (
    <>
      <MediaQuery className={className} fromDisplay={DisplayType.Medium}>
        <Popup
          align={align}
          id="origin-datepicker-input-desktop-popup"
          popupTrigger={
            <DatePickerInput
              ariaLabel={ariaLabel}
              borderRadius={0}
              dateRange={dateRange}
              errorMessage={errorMessage}
              id="origin-datepicker-input-desktop"
              isActive={showDatePickerPopup}
              isOneWay={isOneWay}
              locale={locale}
              setShowPopup={() => onShowDatePickerPopup(!showDatePickerPopup)}
              toggleIsOneWay={toggleIsOneWay}
            />
          }
          showPopup={showDatePickerPopup}
          togglePopup={() => onShowDatePickerPopup(false)}
          withBorder
        >
          <DatePicker
            dateRange={dateRange}
            disabledDays={disabledDays}
            enableRoundTrip={enableRoundTrip}
            isOneWay={isOneWay}
            locale={locale}
            modifiers={modifiers}
            numberOfMonths={2}
            setDateRange={onSetDateRange}
            toggleIsOneWay={toggleIsOneWay}
            weekStartsOn={weekStartsOn}
          />
        </Popup>
      </MediaQuery>
      <MediaQuery className={className} toDisplay={DisplayType.Medium}>
        <DatePickerInput
          dateRange={dateRange}
          id="origin-datepicker-input"
          isActive={showDatePickerModal}
          isOneWay={isOneWay}
          locale={locale}
          setShowPopup={() => onShowDatePickerModal(true)}
          toggleIsOneWay={toggleIsOneWay}
        />
        {showDatePickerModal && (
          <DatePickerModal
            dateRange={dateRange}
            disabledDays={disabledDays}
            isOneWay={isOneWay}
            locale={locale}
            modifiers={modifiers}
            numberOfMonths={2}
            onCloseModal={() => onShowDatePickerModal(false)}
            setDateRange={onSetDateRange}
            title={t('Select dates')}
            toggleIsOneWay={toggleIsOneWay}
            weekStartsOn={weekStartsOn}
          />
        )}
      </MediaQuery>
    </>
  );
};

export default DatePickerContainer;
