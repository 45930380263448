import { useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  UseComboboxGetMenuPropsOptions,
  GetPropsCommonOptions,
  UseComboboxGetItemPropsOptions,
} from 'downshift';
import useEffectOnce from '../../hooks/useEffectOnce';
import CustomInput from '../Input/Input';
import SearchMobileModal from '../SearchMobileModal/SearchMobileModal';
import AutocompleteList from './AutocompleteList';
import { Item } from './AutocompleteTypes';

export interface Props {
  closeMenu: () => void;
  getItemProps: (options: UseComboboxGetItemPropsOptions<Item>) => object;
  getMenuProps: (
    options?: UseComboboxGetMenuPropsOptions,
    otherOptions?: GetPropsCommonOptions,
  ) => object;
  highlightedValue: string | null;
  id: string;
  inputValue?: string;
  isExpandedView: boolean;
  isLoading?: boolean;
  items: Item[];
  leftIcon?: JSX.Element;
  noMatchesMessage: string;
  onInputChange: (inputValue: string) => void;
  onInputClick?: () => void;
  onSelectParentItem: (selectedItem: Item) => void;
  placeholder?: string;
  rightIcon?: JSX.Element;
  selectedParentValue: string | null;
}

const InputWrapper = styled.div(
  ({ theme: { colours, spacings } }) => css`
    padding: ${spacings['16']}px;
    background: ${colours.components.search.surface.default};
  `,
);

const AutocompleteModal = ({
  closeMenu,
  getItemProps,
  getMenuProps,
  highlightedValue,
  id,
  inputValue = '',
  isExpandedView,
  isLoading,
  items,
  leftIcon,
  noMatchesMessage,
  onInputChange,
  onInputClick,
  onSelectParentItem,
  placeholder,
  rightIcon,
  selectedParentValue,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffectOnce(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  });

  return (
    <SearchMobileModal
      id="autocomplete-modal"
      onCloseModal={closeMenu}
      onConfirm={closeMenu}
      title={placeholder}
    >
      <InputWrapper>
        <CustomInput
          isActive
          left={leftIcon}
          onChange={onInputChange}
          onClick={onInputClick}
          placeholder={placeholder}
          ref={inputRef}
          right={rightIcon}
          value={inputValue}
        />
      </InputWrapper>
      <AutocompleteList
        closeMenu={closeMenu}
        getItemProps={getItemProps}
        getMenuProps={getMenuProps}
        highlightedValue={highlightedValue}
        id={id}
        isExpandedView={isExpandedView}
        isLoading={isLoading}
        isOpen
        items={items}
        noMatchesMessage={noMatchesMessage}
        onSelectParentItem={onSelectParentItem}
        selectedParentValue={selectedParentValue}
      />
    </SearchMobileModal>
  );
};

export default AutocompleteModal;
