import React, {
  AriaAttributes,
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
} from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mqMin } from '../../styles/base';
import { InputContainer } from './InputContainer';

type InputTypes =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

export interface Props {
  ariaAutocomplete?: AriaAttributes['aria-autocomplete'];
  ariaControls?: AriaAttributes['aria-controls'];
  ariaLabel?: AriaAttributes['aria-label'];
  ariaLabelledby?: AriaAttributes['aria-labelledby'];
  autoComplete?: string;
  autoFocus?: boolean;
  borderRadius?: number;
  className?: string;
  containerRef?: Ref<HTMLDivElement>;
  disabled?: boolean;
  errorMessage?: string;
  id?: string;
  isActive?: boolean;
  left?: ReactElement;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onClick?: () => void;
  onFocus?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  pattern?: string;
  placeholder?: string;
  readOnly?: boolean;
  right?: ReactElement;
  showError?: boolean;
  type?: InputTypes;
  value: string;
}

export const BaseInput = styled.input<{ hasError: boolean }>(
  ({ hasError, theme: { colours, spacings } }) => [
    css`
      width: 100%;
      height: 40px;
      padding: 0 0 0 ${spacings['8']}px;
      border: none;
      margin: 0;
      background-color: ${colours.surface.selected.default};
      color: ${colours.text.default};

      ${mqMin.Small} {
        height: 50px;
      }
    `,
    hasError &&
      css`
        &::placeholder {
          border-color: ${colours.border.critical};
        }
      `,
  ],
);

const Input = forwardRef<HTMLInputElement | null, Props>(
  (
    {
      ariaAutocomplete,
      ariaControls,
      ariaLabel,
      ariaLabelledby,
      autoComplete,
      autoFocus,
      borderRadius,
      className,
      containerRef,
      disabled,
      errorMessage,
      id,
      isActive = false,
      left,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      pattern,
      placeholder,
      readOnly,
      right,
      showError = false,
      type,
      value,
      ...props
    },
    ref,
  ) => {
    const onChangeValue = useCallback(
      (e: React.FormEvent<HTMLInputElement>) => {
        const eValue = (e.target as HTMLInputElement).value;
        onChange?.(eValue);
      },
      [onChange],
    );

    const hasError = showError || Boolean(errorMessage);

    return (
      <InputContainer
        borderRadius={borderRadius}
        className={className}
        hasError={hasError}
        isActive={isActive}
        ref={containerRef}
      >
        {left}
        <BaseInput
          aria-autocomplete={ariaAutocomplete}
          aria-controls={ariaControls}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledby}
          autoComplete={autoComplete}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          disabled={disabled}
          hasError={hasError}
          id={id}
          onBlur={onBlur}
          onChange={onChangeValue}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          pattern={pattern}
          placeholder={errorMessage || placeholder}
          ref={ref}
          type={type}
          value={value}
          {...props}
        />
        {right}
      </InputContainer>
    );
  },
);

export default React.memo(Input);
