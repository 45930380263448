import { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCombobox } from 'downshift';
import { KeyboardKey, DisplayType } from '@shared/types/enums';
import { legacyBreakpoints } from '@ui-v2/theme/layout';
import { createTypography } from '../../styles/base';
import CustomInput, { BaseInput } from '../Input/Input';
import MediaQuery from '../Responsive/MediaQuery';
import AutocompleteList from './AutocompleteList';
import AutocompleteModal from './AutocompleteModal';
import { Item } from './AutocompleteTypes';
import {
  filterAndSort,
  findItemByValue,
  getFirstItem,
  getUpdatedHighlightedValues,
} from './utils/autoCompleteUtils';

export interface Props {
  className?: string;
  errorMessage?: string;
  id?: string;
  isLoading?: boolean;
  items: Item[];
  leftIcon?: JSX.Element;
  noMatchesMessage: string;
  onSelect?: (item: Item) => void;
  placeholder?: string;
  rightIcon?: JSX.Element;
  selectedItem?: Item;
}

const IconContainer = styled.div(
  ({ theme }) => css`
    padding-left: ${theme.spacings['16']}px;

    ${BaseInput} + & {
      padding-right: ${theme.spacings['16']}px;
      padding-left: 0;
    }
  `,
);

const StyledMediaQuery = styled(MediaQuery)`
  position: absolute;
  min-width: 100%;
  white-space: nowrap;
`;

const Container = styled.div(({ theme: { typography } }) => [
  createTypography(typography.body01),
  css`
    position: relative;
  `,
]);

export const StyledInput = styled(CustomInput)``;

const Autocomplete = ({
  className,
  errorMessage,
  id = 'autocomplete',
  isLoading,
  items,
  leftIcon,
  noMatchesMessage,
  onSelect,
  placeholder,
  rightIcon,
  selectedItem,
}: Props) => {
  const inputValueRef = useRef<string>('');

  const ref = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [highlightedValue, setHighligtedValue] = useState<string | null>(null);

  const [inputItems, setInputItems] = useState<Item[]>(items);

  const [selectedParentValue, setSelectedParentValue] = useState<string | null>(
    null,
  );

  useEffect(() => {
    // Because the input is uncontrolled, we need to clear it if the selectedItem is undefined.
    const value = inputRef.current?.value;

    if (inputRef.current && value && !selectedItem) {
      inputRef.current.value = '';
    }
  }, [inputRef, selectedItem]);

  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    setHighlightedIndex,
  } = useCombobox<Item>({
    items: inputItems,
    itemToString: (item) => item?.value || '',
    id,
    selectedItem,
  });

  const hasSubItems = inputItems.some((item) => item.subItems.length > 0);

  const isExpandedView = inputValueRef.current.length > 1 && hasSubItems;

  const setInputValue = useCallback(
    (value: string) => {
      inputValueRef.current = value;
      const searchValue = value.trim();

      const filteredItems = filterAndSort(items, searchValue);

      let firstItem = getFirstItem(filteredItems, searchValue);

      if (
        !firstItem?.isSelectable &&
        firstItem?.subItems &&
        firstItem.subItems.length > 0 &&
        isExpandedView
      ) {
        [firstItem] = firstItem.subItems;
      }

      setInputItems(filteredItems);
      setHighlightedIndex(0);
      setHighligtedValue(firstItem?.value || null);
    },
    [isExpandedView, items, setHighlightedIndex],
  );

  useEffect(() => {
    // This effect is run when the autocomplete is initilized with default values
    // This can happen when the destination autocomplete uses the origin stations to display while it is waiting/
    // for the request to finish
    // If the user has already filtered by something we need to make sure we don't reset his filtered list but use the updated
    // autocomplete items but apply the current filtered value
    const filteredItems = filterAndSort(items, inputValueRef.current);
    setInputItems(filteredItems);
  }, [items, setInputItems]);

  const onSelectItem = useCallback(
    (item: Item) => {
      onSelect?.(item);
      const filteredItems = filterAndSort(items, item.value);
      setInputItems(filteredItems);
      setIsOpen(false);
      setIsModalOpen(false);
    },
    [items, onSelect],
  );

  const handleFocus = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      event.currentTarget.select();
      setSelectedParentValue(null);
      setInputItems(items);
      setInputValue('');

      if (!isOpen) {
        setIsOpen(true);
      }
    },
    [isOpen, items, setInputValue],
  );

  const onSelectParentItem = useCallback(
    (item: Item) => {
      if (item.isSelectable) {
        onSelectItem(item);
      } else {
        const updatedValue =
          item.value === selectedParentValue ? null : item.value;

        setSelectedParentValue(updatedValue);
        const parentIndex = inputItems.findIndex(
          (item) => item.value === updatedValue,
        );

        setHighlightedIndex(parentIndex);
        setHighligtedValue(item.value);
      }
    },
    [inputItems, onSelectItem, selectedParentValue, setHighlightedIndex],
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // @ts-expect-error avoid default downshift behaviour
      // eslint-disable-next-line functional/immutable-data
      event.nativeEvent.preventDownshiftDefault = true;

      if (event.key === KeyboardKey.Enter && highlightedValue) {
        const item = findItemByValue(items, highlightedValue);

        if (item?.isSelectable) {
          onSelectItem(item);
        } else if (item) {
          onSelectParentItem(item);
        }
      } else if (
        event.key === KeyboardKey.ArrowDown ||
        event.key === KeyboardKey.ArrowUp
      ) {
        const [newHighligtedIndex, newValue] = getUpdatedHighlightedValues({
          highlightedIndex,
          event,
          id,
        });

        setHighlightedIndex(newHighligtedIndex);
        setHighligtedValue(newValue);
      } else if (event.key === KeyboardKey.Tab) {
        setIsOpen(false);
      } else if (event.key === KeyboardKey.Backspace) {
        setIsOpen(true);
      }
    },
    [
      highlightedIndex,
      highlightedValue,
      id,
      items,
      onSelectItem,
      onSelectParentItem,
      setHighlightedIndex,
    ],
  );

  const onInputClick = useCallback(() => {
    const isSmallDevice = window.innerWidth < legacyBreakpoints.Small;

    if (isSmallDevice) {
      setIsModalOpen(true);
    } else {
      setIsOpen(true);
    }
  }, []);

  return (
    <Container className={className} ref={ref}>
      <StyledInput
        {...getInputProps(
          {
            refKey: 'inputRef',
            onKeyDown,
          },
          { suppressRefError: true },
        )}
        data-cy="autocomplete-input"
        errorMessage={errorMessage}
        isActive={isOpen}
        left={leftIcon && <IconContainer>{leftIcon}</IconContainer>}
        onChange={setInputValue}
        onClick={onInputClick}
        onFocus={handleFocus}
        placeholder={placeholder}
        ref={inputRef}
        right={rightIcon && <IconContainer>{rightIcon}</IconContainer>}
        value={isOpen ? undefined : selectedItem?.value}
      />
      <StyledMediaQuery fromDisplay={DisplayType.Small}>
        <AutocompleteList
          closeMenu={() => setIsOpen(false)}
          enableClickOnOutside
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          highlightedValue={highlightedValue}
          id={id}
          isExpandedView={isExpandedView}
          isLoading={isLoading}
          isOpen={isOpen}
          items={inputItems}
          noMatchesMessage={noMatchesMessage}
          onSelectParentItem={onSelectParentItem}
          selectedParentValue={selectedParentValue}
        />
      </StyledMediaQuery>
      {isModalOpen && (
        <AutocompleteModal
          closeMenu={() => setIsModalOpen(false)}
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          highlightedValue={highlightedValue}
          id={id}
          inputValue={inputValueRef.current}
          isExpandedView={isExpandedView}
          items={inputItems}
          leftIcon={leftIcon && <IconContainer>{leftIcon}</IconContainer>}
          noMatchesMessage={noMatchesMessage}
          onInputChange={setInputValue}
          onSelectParentItem={onSelectParentItem}
          placeholder={placeholder}
          selectedParentValue={selectedParentValue}
        />
      )}
    </Container>
  );
};

export default Autocomplete;
