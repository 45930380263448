import { AriaAttributes, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import useTranslation from 'next-translate/useTranslation';
import { createTypography, mqMin } from '../../styles/base';
import Button, { ButtonType } from '../Button/Button';
import { GridRow } from '../Grid/Grid';
import { InputContainer } from '../Input/InputContainer';

export const StyledDatePickerButton = styled(Button)<{ hasError?: boolean }>(
  ({ hasError, theme: { colours, spacings, typography } }) => [
    createTypography(typography.body01),
    css`
      overflow: hidden;
      width: 100%;
      height: 40px;
      padding: 0 0 0 ${spacings['8']}px;
      background-color: ${colours.surface.selected.default};
      color: ${colours.text.default};
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;

      ${mqMin.Small} {
        height: 50px;
      }
    `,
    hasError &&
      css`
        color: ${colours.text.critical};
      `,
  ],
);

interface Props {
  ariaLabel?: AriaAttributes['aria-label'];
  borderRadius?: number;
  className?: string;
  dateRange: DateRange;
  errorMessage?: string;
  id: string;
  isActive: boolean;
  isOneWay: boolean;
  locale?: string;
  setShowPopup: () => void;
  toggleIsOneWay: () => void;
}

const DatePickerInput = ({
  ariaLabel,
  className,
  dateRange,
  errorMessage,
  id,
  isActive,
  isOneWay,
  locale,
  setShowPopup,
}: Props) => {
  const { t } = useTranslation();
  const placeholder = t('Select dates');
  const [buttonValue, setButtonValue] = useState(placeholder);
  const from = dateRange.from ? dayjs(dateRange.from).toDate() : null;
  const to = dateRange.to ? dayjs(dateRange.to).toDate() : null;
  const hasError = Boolean(errorMessage);

  useEffect(() => {
    if (from) {
      setButtonValue(
        `${
          // eslint-disable-next-line no-restricted-syntax
          from.toLocaleString(locale, {
            day: 'numeric',
            month: 'long',
            hour12: false,
          }) || ''
        }${
          isOneWay || !to
            ? ''
            : ` - ${
                // eslint-disable-next-line no-restricted-syntax
                to.toLocaleString(locale, {
                  day: 'numeric',
                  month: 'long',
                  hour12: false,
                }) || ''
              }`
        }`.trim(),
      );
    }
  }, [from, to, isOneWay, setButtonValue, locale]);

  return (
    <GridRow className={className}>
      <InputContainer hasError={hasError} isActive={isActive}>
        <StyledDatePickerButton
          ariaLabel={ariaLabel}
          buttonType={ButtonType.RAW}
          data-cy="datepicker-btn"
          hasError={hasError}
          id={id}
          onClick={setShowPopup}
        >
          {errorMessage ?? buttonValue}
        </StyledDatePickerButton>
      </InputContainer>
    </GridRow>
  );
};

export default DatePickerInput;
