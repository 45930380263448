import { css } from '@emotion/react';
import { mqMin } from './base';

export const reactDayPicker = css`
  /* DayPicker styles */
  /* stylelint-disable selector-class-pattern */

  :root {
    --rdp-cell-width: 38px;
    --rdp-cell-height: 50px;
    --rdp-accent-color: #00f;
    --rdp-background-color: #e7edff;

    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;

    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);

    /* Outline border for focused and selected elements */
    --rdp-outline-selected: 2px solid rgb(0 0 0 / 75%);

    @media (width >= 375px) {
      --rdp-cell-width: 45px;
      --rdp-cell-height: 50px;
    }

    @media (width >= 414px) {
      --rdp-cell-width: 52px;
      --rdp-cell-height: 55px;
    }

    ${mqMin.Small} {
      --rdp-cell-width: 45px;
      --rdp-cell-height: 50px;
    }
  }

  .rdp {
    margin: 1em;
  }

  /* Hide elements for devices that are not screen readers */
  .rdp-vhidden {
    position: absolute !important;
    top: 0;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    box-sizing: border-box;
    padding: 0 !important;
    border: 0 !important;
    margin: 0;
    appearance: none;
    background: transparent;
    clip: rect(1px, 1px, 1px, 1px) !important;
  }

  /* Buttons */
  .rdp-button_reset {
    position: relative;
    padding: 0;
    margin: 0;
    appearance: none;
    background: none;
    color: inherit;
    cursor: default;
    font: inherit;
    outline: none;
  }

  .rdp-button {
    border: 2px solid transparent;
  }

  .rdp-button[disabled] {
    opacity: 0.25;
  }

  .rdp-button:not([disabled]) {
    cursor: pointer;
  }

  .rdp-months {
    display: flex;
  }

  .rdp-month {
    margin: 0 1em;
  }

  .rdp-month:first-of-type {
    margin-left: 0;
  }

  .rdp-month:last-child {
    margin-right: 0;
  }

  .rdp-table {
    margin: 0 -4px;
    border-spacing: 4px;
  }

  .rdp-with_weeknumber .rdp-table {
    max-width: calc(var(--rdp-cell-width) * 8);
    border-collapse: collapse;
  }

  .rdp-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    text-align: left;
  }

  .rdp-multiple_months .rdp-caption {
    position: relative;
    display: block;
    text-align: center;
  }

  .rdp-caption_dropdowns {
    position: relative;
    display: inline-flex;
  }

  .rdp-caption_label {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    padding: 0 0.25em;
    border: 0;
    border: 2px solid transparent;
    margin: 0;
    color: currentcolor;
    font-family: inherit;
    font-size: 140%;
    font-weight: bold;
    white-space: nowrap;
  }

  .rdp-nav {
    white-space: nowrap;
  }

  .rdp-multiple_months .rdp-caption_start .rdp-nav {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .rdp-multiple_months .rdp-caption_end .rdp-nav {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .rdp-nav_button {
    display: inline-flex;
    width: var(--rdp-cell-width);
    height: var(--rdp-cell-height);
    align-items: center;
    justify-content: center;
    padding: 0.25em;
    border-radius: 100%;
  }

  /* ---------- */

  /* Dropdowns  */

  /* ---------- */

  .rdp-dropdown_year,
  .rdp-dropdown_month {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  .rdp-dropdown {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
    border: none;
    margin: 0;
    appearance: none;
    background-color: transparent;
    cursor: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    opacity: 0;
  }

  .rdp-dropdown[disabled] {
    color: unset;
    opacity: unset;
  }

  .rdp-dropdown:focus:not([disabled]) + .rdp-caption_label,
  .rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
    border: var(--rdp-outline);
    border-radius: 6px;
    background-color: var(--rdp-background-color);
  }

  .rdp-dropdown_icon {
    margin: 0 0 0 5px;
  }

  .rdp-head {
    border: 0;
  }

  .rdp-head_row,
  .rdp-row {
    height: 100%;
  }

  .rdp-head_cell {
    height: var(--rdp-cell-height);
    padding: 0;
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
  }

  .rdp-tbody {
    border: 0;
  }

  .rdp-tfoot {
    margin: 0.5em;
  }

  .rdp-cell {
    width: 100%;
    width: var(--rdp-cell-width);
    height: 100%;
    height: var(--rdp-cell-height);
    padding: 0;
    text-align: center;
  }

  .rdp-weeknumber {
    font-size: 0.75em;
  }

  .rdp-weeknumber,
  .rdp-day {
    display: flex;
    overflow: hidden;
    width: var(--rdp-cell-width);
    max-width: var(--rdp-cell-width);
    height: var(--rdp-cell-height);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 4px;
    margin: 0;
    transition:
      border-color 100ms,
      background-color 100ms,
      color 100ms,
      outline 100ms;
  }

  .rdp-day_selected:not([disabled]),
  .rdp-day_selected:focus:not([disabled]),
  .rdp-day_selected:active:not([disabled]),
  .rdp-day_selected:hover:not([disabled]) {
    background-color: var(--rdp-accent-color);
    color: white;
  }
`;
