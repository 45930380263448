import useTranslation from 'next-translate/useTranslation';
import { DisplayType } from '@shared/types/enums';
import useToggle from '../../hooks/useToggle';
import ReadonlyInput from '../Input/ReadonlyInput';
import Popup from '../Popup/Popup';
import MediaQuery from '../Responsive/MediaQuery';
import PassengerCounter, {
  Props as PassengerCounterProps,
} from './PassengerCounter';
import PassengerCounterModal from './PassengerCounterModal';

export type Props = PassengerCounterProps;

const PassengerCounterInput = ({
  addPassenger,
  ariaLabel,
  className,
  disclaimer,
  passengerTypes,
  removePassenger,
  updateAge,
}: Props) => {
  const { t } = useTranslation();
  const [showPassengerCounter, togglePassengerCounter] = useToggle();
  const [showPassengerModal, togglePassengerModal] = useToggle();
  const numberOfPassengers = passengerTypes.reduce<number>(
    (paxNum, paxType) => paxNum + paxType.count,
    0,
  );

  const inputValue = t(
    'number-of-passengers',
    { count: numberOfPassengers },
    { default: `${numberOfPassengers} passengers` },
  );

  return (
    <>
      <MediaQuery className={className} fromDisplay={DisplayType.Small}>
        <Popup
          align="middle"
          id="passenger-counter"
          popupTrigger={
            <ReadonlyInput
              ariaLabel={ariaLabel}
              data-cy="passenger-input"
              onFocus={togglePassengerCounter}
              value={inputValue}
            />
          }
          showPopup={showPassengerCounter}
          togglePopup={togglePassengerCounter}
          width={300}
          withBorder
        >
          <PassengerCounter
            addPassenger={addPassenger}
            disclaimer={disclaimer}
            passengerTypes={passengerTypes}
            removePassenger={removePassenger}
            updateAge={updateAge}
          />
        </Popup>
      </MediaQuery>
      <MediaQuery className={className} toDisplay={DisplayType.Small}>
        <ReadonlyInput onClick={togglePassengerModal} value={inputValue} />
        {showPassengerModal && (
          <PassengerCounterModal
            addPassenger={addPassenger}
            disclaimer={disclaimer}
            onCloseModal={togglePassengerModal}
            passengerTypes={passengerTypes}
            removePassenger={removePassenger}
            title={t('Passengers')}
            updateAge={updateAge}
          />
        )}
      </MediaQuery>
    </>
  );
};

export default PassengerCounterInput;
