import { AriaRole, ReactElement, cloneElement, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { legacyZIndices } from '@ui-v2/theme/zIndices';
import { useBaseAnalytics } from '@ui-v2/utils/contexts/BaseAnalyticsContext';
import useOnOutsideClick from '../../hooks/useOnOutsideClick';
import { createTypography } from '../../styles/base';

export type Align = 'left' | 'middle' | 'right';

export interface Props {
  align: Align;
  children: React.ReactNode;
  id: string;
  popupTrigger: ReactElement;
  role?: AriaRole;
  showPopup: boolean;
  togglePopup: () => void;
  width?: number;
  withBorder?: boolean;
}

const getPosition = (align: Align) => {
  if (align === 'right') {
    return css`
      right: -5px;

      &::after {
        right: 10px;
      }
    `;
  } else if (align === 'left') {
    return css`
      left: -5px;

      &::after {
        left: 10px;
      }
    `;
  }

  return css`
    left: 50%;
    transform: translateX(-50%);

    &::after {
      left: 50%;
      transform: translateX(-50%);
    }
  `;
};

const Container = styled.div`
  position: relative;
`;

export const PopupContainer = styled.div<{
  align: Align;
  width?: number;
  withBorder?: boolean;
}>(
  ({
    align,
    theme: { colours, shadows, shape, spacings, typography },
    width,
    withBorder,
  }) => [
    createTypography(typography.body01),
    getPosition(align),
    css`
      position: absolute;
      z-index: ${legacyZIndices.max};
      width: ${width && `${width}px`};
      padding: ${spacings['16']}px;
      border-radius: ${shape.borderRadiusS}px;
      margin-top: 10px;
      background: ${colours.surface.main};
      box-shadow: ${shadows.medium};
      color: ${colours.text.default};

      &::after {
        position: absolute;
        top: -14px;
        border: 8px solid transparent;
        border-bottom-color: ${colours.surface.main};
        content: '';
      }
    `,
    withBorder &&
      css`
        border-bottom: 4px solid ${colours.brand.primary};
      `,
  ],
);

const Popup = ({
  align,
  children,
  id,
  popupTrigger,
  role,
  showPopup,
  togglePopup,
  width,
  withBorder,
}: Props) => {
  const popupRef = useRef(null);
  const triggerRef = useRef(null);
  const { sendPopupShownEvent } = useBaseAnalytics();
  useOnOutsideClick(popupRef, togglePopup, triggerRef);

  const popupTriggerWithAnalytics = cloneElement(popupTrigger, {
    onClick: () => {
      if (!showPopup) {
        sendPopupShownEvent({ id });
      }
    },
  });

  return (
    <Container>
      <div ref={triggerRef}>{popupTriggerWithAnalytics}</div>
      {showPopup && (
        <PopupContainer
          align={align}
          id={id}
          ref={popupRef}
          role={role}
          width={width}
          withBorder={withBorder}
        >
          {children}
        </PopupContainer>
      )}
    </Container>
  );
};

export default Popup;
