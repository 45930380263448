import dayjs, { Dayjs } from 'dayjs';
import { DayModifiers } from 'react-day-picker';
import { isSameDay } from '@utils/dateUtils';

export const addDayToRange = (
  day: Dayjs | undefined,
  range: DateRange = { from: undefined, to: undefined },
) => {
  let { from, to } = range;

  if (!from) {
    from = day;
  } else if (to && isSameDay(from, to) && isSameDay(day, from)) {
    from = undefined;
    to = undefined;
  } else if (to && dayjs(day).isBefore(to)) {
    from = day;
  } else if (to && isSameDay(day, to)) {
    from = day;
    to = day;
  } else {
    to = day;

    if (dayjs(to).isBefore(from)) {
      to = from;
      from = day;
    }
  }

  return { from, to };
};

export const detectCloseActionRequired = ({
  isOneWay,
  newFrom,
  newTo,
  to,
}: {
  isOneWay: boolean;
  newFrom?: Dayjs | null;
  newTo?: Dayjs | null;
  to?: Dayjs | null;
}) => {
  const isUpdatedToDateTheSame = isSameDay(to, newTo);

  // If this is a oneWay route we always close the modal after each selection
  if (isOneWay) {
    return true;
  }

  if (newFrom && newTo && !isUpdatedToDateTheSame) {
    return true;
  }

  return false;
};

// Update the dateRange depending on the given day
export const setNewDate = (
  day: Dayjs,
  modifiers: DayModifiers,
  dateRange: DateRange,
  setDateRange: (dateRange: DateRange) => void,
  isOneWay: boolean,
) => {
  if (modifiers['disabled']) {
    // Date is disabled, do nothing
    return;
  }

  // Sets new date as 'from' if before original
  // Sets new date as 'to' of it is after 'from'
  // Sets both days to the same date if double clicked on a date
  const { from, to } = addDayToRange(day, dateRange);

  if (isSameDay(from, to)) {
    setDateRange({ from, to: undefined });
  } else if (Boolean(dateRange.from && dateRange.to) || isOneWay) {
    setDateRange({ from: day, to: undefined });
  } else {
    setDateRange({ from, to });
  }
};

// Adds a number of days to the date object
export const addDaysToDate = (dateObj: Date | Dayjs, numDays: number) => {
  return dayjs(dateObj).add(numDays, 'day');
};
