import { Fragment, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  UseComboboxGetMenuPropsOptions,
  GetPropsCommonOptions,
  UseComboboxGetItemPropsOptions,
} from 'downshift';
import { legacyZIndices } from '@ui-v2/theme/zIndices';
import useOnOutsideClick from '../../hooks/useOnOutsideClick';
import { createTypography, mqMin } from '../../styles/base';
import Loading from '../Loading/Loading';
import AutocompleteItem from './AutocompleteItem';
import { Item } from './AutocompleteTypes';

export interface Props {
  className?: string;
  closeMenu: () => void;
  enableClickOnOutside?: boolean;
  getItemProps: (options: UseComboboxGetItemPropsOptions<Item>) => object;
  getMenuProps: (
    options?: UseComboboxGetMenuPropsOptions,
    otherOptions?: GetPropsCommonOptions,
  ) => object;
  highlightedValue: string | null;
  id: string;
  isExpandedView: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  items: Item[];
  noMatchesMessage: string;
  onSelectParentItem: (selectedItem: Item) => void;
  selectedParentValue: string | null;
}

const List = styled.ul<{ isExpandedView: boolean; isOpen: boolean }>(
  ({ isExpandedView, isOpen, theme: { colours, shadows, typography } }) => [
    createTypography(typography.body01),
    css`
      overflow: auto;
      max-height: unset;
      padding: 0;
      box-shadow: ${shadows.medium};
      list-style: none;

      ${mqMin.Small} {
        position: relative;
        z-index: ${legacyZIndices.z2};
        overflow: auto;
        max-height: 310px;
      }
    `,
    isOpen &&
      css`
        min-width: 243px;
        border: unset;
        border-radius: 0;
        border-bottom: 4px solid ${colours.brand.primary};
      `,
    isExpandedView &&
      css`
        ${mqMin.Small} {
          position: absolute;
        }
      `,
  ],
);

const LoadingWrapper = styled.li(
  ({ theme: { colours, spacings } }) => css`
    padding: ${spacings['4']}px;
    background: ${colours.surface.main};
    color: ${colours.text.default};
  `,
);

const AutocompleteList = ({
  className,
  closeMenu,
  enableClickOnOutside,
  getItemProps,
  getMenuProps,
  highlightedValue,
  id,
  isExpandedView,
  isLoading,
  isOpen,
  items,
  noMatchesMessage,
  onSelectParentItem,
  selectedParentValue,
}: Props) => {
  const ref = useRef<HTMLUListElement | null>(null);

  useOnOutsideClick(ref, () => (enableClickOnOutside ? closeMenu() : {}));

  return (
    <List
      {...getMenuProps({}, { suppressRefError: true })}
      className={className}
      isExpandedView={isExpandedView || Boolean(selectedParentValue)}
      isOpen={isOpen}
      ref={ref}
    >
      {isOpen &&
        (items.length === 0 ? (
          <LoadingWrapper>
            {isLoading ? <Loading diameter={25} /> : noMatchesMessage}
          </LoadingWrapper>
        ) : (
          items.map((item, index) => (
            <Fragment key={`${id}${item.value}${index.toString()}`}>
              <AutocompleteItem
                getItemProps={getItemProps}
                highlightedValue={highlightedValue}
                id={id}
                index={index}
                isExpandedView={isExpandedView}
                item={item}
                onSelectItem={onSelectParentItem}
                showItem={!isExpandedView || item.subItems.length === 0}
                showListIcon
                showSubtree={
                  selectedParentValue === item.value || isExpandedView
                }
              />
            </Fragment>
          ))
        ))}
    </List>
  );
};

export default AutocompleteList;
