import { useSearchWidget } from '../contexts/SearchWidgetContext';
import useSearchConfig from '../hooks/useSearchConfig';
import SearchWidget from './SearchWidget';
import SearchWidgetSeperateFields from './Variants/SearchWidgetSeperateFields';

interface Props {
  className?: string;
  inTransition?: boolean;
  showTitleOnTablets?: Maybe<boolean>;
}

const SearchWidgetContainer = ({
  className,
  inTransition,
  showTitleOnTablets,
}: Props) => {
  const props = useSearchWidget();
  const { variant } = useSearchConfig();

  switch (variant) {
    case 'seperated-fields':
      return (
        <SearchWidgetSeperateFields
          className={className}
          inTransition={inTransition}
          showTitleOnTablets={showTitleOnTablets}
          {...props}
        />
      );
    default:
      return (
        <SearchWidget
          className={className}
          inTransition={inTransition}
          showTitleOnTablets={showTitleOnTablets}
          {...props}
        />
      );
  }
};

export default SearchWidgetContainer;
