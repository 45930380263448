import styled from '@emotion/styled';
import Input, { BaseInput, Props } from './Input';

export const BaseReadonlyInput = styled(Input)`
  caret-color: transparent;

  ${BaseInput} {
    cursor: pointer;
  }
`;

const ReadonlyInput = (props: Props) => {
  return <BaseReadonlyInput {...props} readOnly />;
};

export default ReadonlyInput;
