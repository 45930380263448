import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Dayjs } from 'dayjs';
import useTranslation from 'next-translate/useTranslation';
import { DayPickerBase, Modifiers } from 'react-day-picker';
import { DisplayType, Language } from '@shared/types/enums';
import { createTypography, mqMin } from '../../styles/base';
import Button, { ButtonSize, ButtonType } from '../Button/Button';
import { GridColumn, GridRow } from '../Grid/Grid';
import { Align } from '../Popup/Popup';
import DatePickerCalendar from './DatePickerCalendar';

export interface Props {
  align?: Align;
  dateRange: DateRange;
  disabledDays?: (day: Dayjs) => boolean;
  enableRoundTrip?: boolean;
  isOneWay: boolean;
  locale: Language;
  modifiers?: Partial<Modifiers>;
  numberOfMonths: number;
  setDateRange: (dateRange: DateRange) => void;
  toggleIsOneWay: () => void;
  weekStartsOn?: DayPickerBase['weekStartsOn'];
}

const StyledControls = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ${theme.spacings['16']}px;
    border-top: 1px solid ${theme.colours.border.default};
    margin: 0 ${theme.spacings['16']}px;

    ${mqMin.Small} {
      margin: 0;
    }
  `,
);

const StyledButton = styled(Button)(({ theme: { typography } }) => [
  createTypography(typography.body01),
  css`
    display: inline-block;
    width: unset;
    margin-left: auto;
  `,
]);

const StyledGridRow = styled(GridRow)(
  ({ theme: { spacings } }) => css`
    padding-bottom: ${spacings['16']}px;
    overflow-x: hidden;
    overflow-y: auto;

    ${mqMin.Small} {
      padding-bottom: unset;
    }
  `,
);

const DatePicker = ({
  dateRange,
  disabledDays,
  enableRoundTrip = true,
  isOneWay,
  locale,
  modifiers,
  numberOfMonths,
  setDateRange,
  toggleIsOneWay,
  weekStartsOn,
}: Props) => {
  const { t } = useTranslation();

  return (
    <StyledGridRow direction="column">
      <GridColumn
        direction={['column', 'column', 'column', 'row']}
        display={['flex']}
        px={[
          DisplayType.ExtraExtraSmall,
          DisplayType.ExtraSmall,
          DisplayType.Small,
          0,
        ]}
        width={[12]}
      >
        <DatePickerCalendar
          dateRange={dateRange}
          disabledDays={disabledDays}
          isOneWay={isOneWay}
          locale={locale}
          modifiers={modifiers}
          numberOfMonths={numberOfMonths}
          setDateRange={setDateRange}
          weekStartsOn={weekStartsOn}
        />
      </GridColumn>
      <StyledControls>
        {enableRoundTrip && (
          <StyledButton
            buttonSize={ButtonSize.Small}
            buttonType={ButtonType.TERTIARY}
            dataCy="roundtrip-t"
            onClick={toggleIsOneWay}
          >
            {isOneWay ? t('Round trip') : t('One way')}
          </StyledButton>
        )}
      </StyledControls>
    </StyledGridRow>
  );
};

export default DatePicker;
