import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StyledInput } from '@ui/components/Autocomplete/Autocomplete';
import { InputContainer } from '@ui/components/Input/InputContainer';
import { mqMin } from '@ui/styles/base';
import { legacySpacings } from '@ui-v2/theme/layout';
import SearchWidget, {
  StyledButton,
  StyledDatePickerContainer,
  StyledAutocomplete,
  StyledFirstAutocomplete,
  Props,
  StyledPassengerCounterInput,
} from '../SearchWidget';
import { StyledGridRow } from '../SearchWidgetStyledComponents';

const StyledSearchWidget = styled(SearchWidget)<{
  useFloatingSearchWidget?: boolean;
}>(
  ({ theme: { colours, shape, sizes, spacings }, useFloatingSearchWidget }) => [
    css`
      background: ${colours.surface.main};

      ${mqMin.Small} {
        margin: 0;
      }

      ${StyledGridRow} {
        padding: ${spacings['16']}px;
        gap: ${legacySpacings.Medium}px;

        ${mqMin.Small} {
          padding: 0 ${legacySpacings.Medium}px;
          gap: ${spacings['8']}px;
        }
      }

      ${InputContainer},
      ${StyledDatePickerContainer} ${InputContainer},
      ${StyledPassengerCounterInput} ${InputContainer} {
        border: 1px solid ${colours.border.default};
        border-radius: ${shape.searchWidgetBorderRadius}px;

        &:focus-within {
          border: 2px solid ${colours.border.strong};
        }
      }

      ${StyledButton} {
        height: 52px;
        border-radius: ${shape.buttonPrimaryBorderRadius}px;
      }

      ${StyledAutocomplete},
      ${StyledFirstAutocomplete} {
        ${StyledInput} {
          border: 1px solid ${colours.border.default};
          border-radius: ${shape.searchWidgetBorderRadius}px;
        }
      }
    `,
    useFloatingSearchWidget &&
      css`
        width: 100%;
        max-width: ${sizes.container}px;
        border-radius: ${shape.searchWidgetBorderRadius}px;
      `,
  ],
);

const SearchWidgetSeperateFields = (props: Props) => {
  return <StyledSearchWidget {...props} />;
};

export default SearchWidgetSeperateFields;
