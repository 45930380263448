import { DayPickerBase } from 'react-day-picker';
import { useGetSearchConfigQuery } from '@codegen/cmsUtils';
import { useConstants } from '@web/context/ConstantContext';
import { useExperiments } from '@web/context/ExperimentContext';
import { constructSearchWidgetConfig } from '@web/utils/search/searchWidgetUtils';

const useSearchConfig = () => {
  const { partner } = useConstants();
  const { experimentData } = useExperiments();
  const { data, isLoading } = useGetSearchConfigQuery(
    {
      partner,
    },
    { retry: 2, keepPreviousData: true },
  );

  const searchConfig =
    experimentData?.searchConfig || data?.partner?.searchConfig;

  return {
    ...constructSearchWidgetConfig(searchConfig?.searchWidgetConfig),
    searchFiltersConfig: searchConfig?.searchFiltersConfig,
    useBookingSteps: searchConfig?.showBookingSteps ?? false,
    newBookingFlowEnvironments: searchConfig?.newIbe ?? [],
    weekStartsOn: searchConfig?.searchWidgetConfig?.weekdayStartsOn as
      | DayPickerBase['weekStartsOn']
      | undefined,
    showInputLabels: searchConfig?.searchWidgetConfig?.showInputLabels ?? false,
    isLoading,
  };
};

export default useSearchConfig;
